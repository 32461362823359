<template>
  <div>
    <div class="nuestros-socios">
      <div class="titulo">
        Nuestros socios
        <!-- <div class="subtitulo">
          Busca tu oficina mas cercana
        </div> -->
      </div>
      <div class="laboratorios">
          <b-carousel
            class="carrusel-labs"                        
            controls          
            >
              <b-carousel-slide              
              >
                <template slot="img">
                  <div class="row">
                    <div class="col-12 col-lg-3 text-center p-3 p-lg-0 d-flex flex-column align-items-center">
                      <img src="@/assets/logos/bruluart.jpg" class="laboratorio img-fluid mb-5" alt="">                      
                      <img src="@/assets/logos/bruluagsa.jpg" class="laboratorio img-fluid" alt="">
                    </div>
                    <div class="col-12 col-lg-3 text-center p-3 p-lg-0 d-flex flex-column align-items-center">
                      <img src="@/assets/logos/alpharma.jpg" class="laboratorio img-fluid mb-5" alt="">                      
                      <img src="@/assets/logos/collins.jpg" class="laboratorio img-fluid" alt="">
                    </div>
                    <div class="col-12 col-lg-3 text-center p-3 p-lg-0 d-flex flex-column align-items-center">
                      <img src="@/assets/logos/amsa.jpg" class="laboratorio img-fluid mb-5" alt="">                      
                      <img src="@/assets/logos/dkt.jpg" class="laboratorio img-fluid" alt="">
                    </div>
                    <div class="col-12 col-lg-3 text-center p-3 p-lg-0 d-flex flex-column align-items-center">
                      <img src="@/assets/logos/genomma-lab.jpg" class="laboratorio img-fluid mb-5" alt="">                      
                      <img src="@/assets/logos/hormona.jpg" class="laboratorio img-fluid" alt="">          
                    </div>                
                  </div>
                </template>
              </b-carousel-slide>  
              <b-carousel-slide>
                <template slot="img">
                  <div class="row">
                    <div class="col-12 col-lg-3 text-center p-3 p-lg-0 d-flex flex-column align-items-center">
                      <img src="@/assets/logos/grossman.jpg" class="laboratorio img-fluid mb-5" alt="">                      
                      <img src="@/assets/logos/maver.jpg" class="laboratorio img-fluid" alt="">
                    </div>
                    <div class="col-12 col-lg-3 text-center p-3 p-lg-0 d-flex flex-column align-items-center">
                      <img src="@/assets/logos/laboratorios-serral.jpg" class="laboratorio img-fluid mb-5" alt="">                      
                      <img src="@/assets/logos/mavi-farmaceutica.jpg" class="laboratorio img-fluid" alt="">
                    </div>
                    <div class="col-12 col-lg-3 text-center p-3 p-lg-0 d-flex flex-column align-items-center">
                      <img src="@/assets/logos/loeffler.jpg" class="laboratorio img-fluid mb-5" alt="">                      
                      <img src="@/assets/logos/novag.jpg" class="laboratorio img-fluid" alt="">
                    </div>
                    <div class="col-12 col-lg-3 text-center p-3 p-lg-0 d-flex flex-column align-items-center">
                      <img src="@/assets/logos/sons.jpg" class="laboratorio img-fluid mb-5" alt="">                      
                      <img src="@/assets/logos/ultra-laboratorios.jpg" class="laboratorio img-fluid" alt="">                  
                    </div>                
                  </div>
                </template>
              </b-carousel-slide>
          </b-carousel>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.nuestros-socios {
  margin-top: 10em;
  margin-bottom: 5em;

  .titulo{
    // text-center font-size-30 font-weight-bold mb-5
    text-align: center;
    font-size: 30px!important;
    font-weight: bold;
    margin-bottom: 2em;

    .subtitulo{
      color:#929292;
      font-weight: 400;
      font-size: 20px;
    }
  }
}

.laboratorio{
  // display: block;
  max-width: 210px;
  // min-height: 16px;
  max-height: 54px;
  // width: auto;
  // height: auto;
  // margin: 1em;  
  object-fit: contain;
  // margin: 2em 2em;
}

.laboratorios{  
  //display: flex;
  //justify-content: center;
  //margin: 20%;
    margin: 5em; 
  
    @media only screen and (max-width: 992px) {
        margin: 0em
    }
}
</style>