<template>
  <div class="pie-de-pagina">
    <div class="row no-gutters">
        <div class="col-12 col-lg-3 d-lg-flex justify-content-center align-items-start ml-3 ml-lg-0">
            <img src="@/assets/logo-blanco.png" alt="" class="logo-blanco">            
        </div>
        <div class="col-12 col-lg-3">
            <div class="mt-4 mt-lg-0 pl-3">
                <div class="opcion">
                    <a href="#inicio" v-smooth-scroll>Inicio</a>
                </div>
                <div class="opcion">
                    <a href="#acerca-de" v-smooth-scroll>Acerca de</a>
                </div>
                <div class="opcion">
                    <a href="#contacto" v-smooth-scroll>Contacto</a>
                </div>
            </div>                        
        </div>
        <div class="col-12 col-lg-3">
            <div class="pl-4 mt-5 mt-lg-0">
                <div class="font-weight-bold mb-2  d-flex align-items-center"><img src="@/assets/clock.svg" class="mr-2">Horario de atención</div>
                <div class="mb-4 pl-3">
                    8:00 a 18:30 Hrs
                </div>

                <div class="font-weight-bold mb-2  d-flex align-items-center"><img src="@/assets/phone.svg" class="mr-2">Teléfono</div>
                <div class="pl-3">
                    800 400 3002
                </div>
                <div class="pl-3">
                    667 713 8950
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-3">
            <div class="pl-3 mt-5 mt-lg-0 mb-5">
                <div class="opcion">
                    <a href="https://farmaceuticaquiroga.com/aviso_privacidad.pdf">Aviso de privacidad</a>
                </div>
                <div class="opcion">
                    <a href="#">Términos y Políticas</a>
                </div>
                <div class="opcion">
                    &#169; 2022 Todos los derechos reservados
                </div>
            </div>
            
        </div>
    </div>

  </div>
</template>

<script>
export default {
    data () {
        return {
            publicPath: process.env.BASE_URL
        }
    },
    computed:{
        // pdf(){
        //     return require('@/assets/aviso_privacidad.pdf');
        // }
    }

}
</script>

<style lang="scss" scoped>
.pie-de-pagina{
    min-height: 260px;
    background: #1b75bc;
    margin-top: 5em;

    padding-top: 4em;    

    color:white;

    font-family: 'Montserrat', sans-serif;    
}

.logo-blanco{
    width: 175px;
}

.opcion{
    color:#fff;
    padding: 0.5em;

    a{
        color:#fff;

        &:hover{
            font-weight: bold;
            text-decoration: none;
        }
    }
}

</style>