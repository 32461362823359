import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@fortawesome/fontawesome-free/css/all.css'
import '@/assets/site.css'

import VueSmoothScroll from 'vue2-smooth-scroll'
Vue.use(VueSmoothScroll)

import 'aos/dist/aos.css';
import AOS from 'aos';


//plugins
import Swal from '@/plugins/swal'
import Msg from '@/plugins/msg'

Vue.use(Swal)
Vue.use(Msg)


Vue.config.productionTip = false


new Vue({
  router,
  store,  
  render: h => h(App),
  mounted(){
    AOS.init({
      // once: true,
      // disable: 'mobile'
      duration: 1500,
      easing: 'ease',
      offset: 100,
      mirror: true, 
    });
  }
}).$mount('#app')
