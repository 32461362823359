<template>
  <div>
    <div class="ubicaciones">
      <div class="titulo mb-4">        
        Ubicaciones
        <div class="subtitulo">
          Busca tu oficina mas cercana
        </div>
      </div>
      <div>
        <div class="w-100" v-if="!mostrarMaps">
          <img src="@/assets/Mapa.png" class="img-fluid mb-2 w-100" alt="">
        </div>
        <iframe v-else :src="url" class="mapa" width="100%"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        <div class="row no-gutters">
          <div class="col-12 col-lg-4 ubicacion" :class="{'ubicacion-active': codSucursal == 1}" @click="navegar(1)">
            <div class="titulo mb-3 text-left">
              Sinaloa
            </div>
            <div>
              Av. Poniente #2084, Col. Las Flores, C.P. 80159
            </div>
            <div class="mt-3">
              Culiacán Rosales, Sinaloa.
            </div>
          </div>

          <div class="col-12 col-lg-4 ubicacion" :class="{'ubicacion-active': codSucursal == 2}" @click="navegar(2)">
            <div class="titulo mb-3 text-left" >
              Chihuahua
            </div>
            <div>
              Calle Cordillera de los andes #5740, Col. la cuesta, C.P. 32650
            </div>
            <div class="mt-3">
              CD. Juarez, Chihuahua
            </div>
          </div>

          <div class="col-12 col-lg-4 ubicacion" :class="{'ubicacion-active': codSucursal == 3}" @click="navegar(3)">
            <div class="titulo mb-3 text-left">
              Baja California
            </div>
            <div>
              Blvd. 3ra. Oeste #17609-A, Col. Garita de Otay, C.P. 22430
            </div>
            <div class="mt-3">
              Tijuana, Baja California
            </div>
          </div>

        </div>
      </div>
      
    </div>

  </div>
</template>

<script>
export default {
  data(){
    return {
      mostrarMaps:false,
      url:'',
      codSucursal: 1,
    }
  },
  methods:{
    navegar(codSucursal){
      this.mostrarMaps = true
      this.codSucursal = codSucursal

      if(codSucursal === 1){
        this.url = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3622.3632869747116!2d-107.43776669790246!3d24.78301085446009!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86bcd7574d41b677%3A0xb3523e3e94007195!2sDISTRIBUCIONES%20FARMACEUTICAS%20QUIROGA%20DEL%20NOROESTE%20S.A.de%20C.V.!5e0!3m2!1ses!2smx!4v1657690628425!5m2!1ses!2smx'
      }
      else if(codSucursal === 2){
        this.url = 'https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3395.451860103536!2d-106.443326!3d31.676213!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzHCsDQwJzM0LjQiTiAxMDbCsDI2JzM2LjAiVw!5e0!3m2!1ses!2smx!4v1685692818711!5m2!1ses!2smx'
      }
      else if(codSucursal == 3){
        this.url = 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3885.9484030962767!2d-116.94827189318173!3d32.5413531486208!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80d947512ddf1637%3A0x802f477d6f108537!2sDistribuciones%20Farmaceuticas%20Quiroga%20del%20Noroeste%20SA%20de%20CV!5e0!3m2!1ses-419!2smx!4v1664415343270!5m2!1ses-419!2smx'
      }

    }
  }

}
</script>

<style lang="scss" scoped>

.ubicacion-active{
  background: #1b75bc;
  color:#fff
}

.ubicaciones{
  margin: 5em; 
  margin-top: 10em; 
}

.ubicacion {
  padding: 3em;
  cursor: pointer;

  &:hover{
    background: #1b76bc5b;
    color: #212121;
  }

  @media only screen and (max-width: 992px) {
    padding: 1em;
  }
}

.mapa{
  height: 600px;
  border: none;

  @media only screen and (max-width: 992px) {
    height: 500px;
  }
}

@media only screen and (max-width: 992px) {
  .ubicaciones, .laboratorios{
      margin: 0em;        
  }

  // .ubicacion{
  //   padding: 1em;
  // }
 }
</style>