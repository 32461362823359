<template>
  <div>
    <div class="row mt-5 mt-lg-0">      
      <div class="col-12 col-lg-6 d-flex align-items-center justify-content-end">        
        <div class="position-relative">
          <img src="@/assets/DFQ_LOGO_HORIZONTAL_1@3x.png" class="logo d-none d-lg-block" alt="">
          
          <div class="bloque-texto">
            <div class="cuadricula">
              <img src="@/assets/cuadricula.png" alt="">
            </div>
            
            <h1 class="titulo-1 pb-4">Dedicados a la distribución de medicamentos</h1>          
            <div class="texto pb-5">
              Brindando un servicio de calidad, contribuyendo a mejorar la salud de la población.
            </div>
            <button class="btn btn-menu" @click="click">
                Venta en linea
            </button>
          </div>          
        </div>
      </div>
      <div class="col-12 col-lg-6 d-flex align-items-center justify-content-lg-start justify-content-center  mt-5 mt-lg-0">        
        <!-- <img src="@/assets/1.jpeg" class="img-fluid img-1" alt=""> -->
        <b-carousel
            class="carrusel-labs" 
            fade                                             
            >
              <b-carousel-slide                
                img-src="@/assets/1-1.png"
              ></b-carousel-slide> 
              <b-carousel-slide                
                img-src="@/assets/1-2.png"
              ></b-carousel-slide> 
              <b-carousel-slide                
                img-src="@/assets/1-3.png"
              ></b-carousel-slide>                              
          </b-carousel>
      </div>      
    </div>
  </div>
</template>

<script>
export default {
  methods:{
        click(){
            window.location.href='https://www.farmaceuticaquiroga.com/sitio/#/login'
        }
    }
}
</script>

<style lang="scss" scoped>
.logo{
  width: 330px;
  margin-bottom: 1.5em;
}

.titulo-1{
  font-weight: bold;
  font-size: 42px;
  margin-bottom: .25rem;
}

.texto{
  font-size: 18px;
  color:#929292;
}

.cuadricula{  
  position: absolute;  
  left: -210px;
  img{
    height: 170px;
  }  
}

.bloque-texto {
  max-width: 600px;
}

.img-1{
  width: 528px;
}

</style>