<template>
  <div>
    <div class="row a-que-nos-dedicamos pt-4" id="acerca-de">
      <div class="col-12 col-lg-6 d-lg-flex align-items-center justify-content-center d-none">        
        <img src="@/assets/2n.png" class="img-fluid a-que-nos-dedicamos-img" alt="">
      </div>
      <div class="col-12 col-lg-6 d-flex align-items-center justify-content-lg-start justify-content-center">
          <div class="bloque-texto font-size-24">
            <div class="font-size-30 font-weight-bold pb-4 mt-5">A que nos dedicamos</div> 
            <div class="texto">
              <p>
                Somos una empresa de distribución de producto farmacéutico en diversas entidades del país.
              </p>
              <p>
                Contamos con una variedad y disponibilidad de inventario en producto patente, genérico y material de curación, garantizando el abastecimiento y colocación del producto directo a nuestros clientes.
              </p>
              <p>
                En tiempo oportuno con precios competitivos y garantizando que el producto llegue en optimas condiciones.
              </p>
            </div>
            <button class="btn btn-menu mt-5" @click="click">
                Venta en linea
            </button>
          </div>          
      </div>

      <div class="col-12 mt-5 d-flex justify-content-center d-lg-none">        
        <img src="@/assets/2n.png" class="img-fluid a-que-nos-dedicamos-img" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
    methods:{
        click(){
            window.location.href='https://www.farmaceuticaquiroga.com/sitio/#/login'
        }
    }
}
</script>

<style lang="scss" scoped>
.titulo-1{
  font-weight: bold;
  font-size: 42px;
  margin-bottom: .25rem;
}

.texto{
  font-size: 18px;
  color:#929292;
}

.bloque-texto {
  max-width: 600px;
}

.a-que-nos-dedicamos{
  margin-top: 7em;
  margin-bottom: 7em;
}


.a-que-nos-dedicamos-img {
  box-shadow: 50px 30px 2px 1px #e5f4ff;
  border-radius: 20px;
  width: 413px;

}
</style>