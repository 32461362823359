<template>
  <div class="como-podemos-ayudarte">
      <div class="mb-4">
        <div class="titulo">¿Cómo podemos ayudarte?</div>
        <div class="subtitulo">¡Contáctanos!</div>
      </div>
      <div class="d-flex justify-content-center">
        <div class="formulario">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="control">
                <div class="label">Selecciona el area deseada</div>
                <select class="input" v-model="contacto.idArea">
                    <option :value="1">Atención a cliente</option>
                    <option :value="2">Ventas</option>
                    <option :value="3">Compras</option>
                    <option :value="4">Recursos humanos</option>
                </select>
              </div>              
            </div>

            <div class="col-12 col-lg-6">
              <div class="control">
                <label for="nombre" class="label">Nombre</label>
                <input id="nombre" type="text" class="input" v-model="contacto.nombre">
              </div>              
            </div>
            <div class="col-12 col-lg-6">
              <div class="control">
                <label for="apellido" class="label">Apellido</label>
                <input id="apellido" type="text" class="input" v-model="contacto.apellido">
              </div>
            </div>            

            <div class="col-12 col-lg-6">
              <div class="control">
                <label for="telefono" class="label">Teléfono</label>
                <input id="telefono" type="text" class="input" v-model="contacto.telefono">
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="control">
                <label for="correo" class="label">Correo electrónico</label>
                <input id="correo" type="text" class="input" v-model="contacto.correo">
              </div>
            </div>

            <div class="col-12 col-lg-6">
              <div class="control">
                <label for="estado" class="label">Estado</label>
                <input id="estado" type="text" class="input" v-model="contacto.estado">
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="control">
                <label for="codigo-postal" class="label">Código postal</label>
                <input id="codigo-postal" type="text" class="input" v-model="contacto.codigoPostal">
              </div>
            </div>

            <div class="col-12">
              <div class="control">
                <label for="mensaje" class="label">Mensaje</label>
                <textarea id="mensaje" cols="30" rows="5" class="input" v-model="contacto.mensaje"></textarea>
              </div>
              
            </div>

            <div class="col-12 pt-3">
              <button class="btn btn-block btn-primary" @click="enviarCorreo" :disabled="loading">{{mensaje}}</button>
            </div>
          </div>     
          
        </div>
      </div>  

  </div>
  
</template>

<script>
import axios from 'axios'

export default {
  data(){
    return {
      contacto:{
        idArea: 1,
        nombre: '',
        apellido: '',
        telefono: '',
        correo: '',
        estado: '',
        codigoPostal: '',
        mensaje: ''
      },
      loading: false,
      mensaje: 'Enviar'
    }
  },
  methods:{
    async enviarCorreo(){
      this.mensaje = "Espere un momento ..."
      this.loading = true

      let response = await axios.post('https://farmaceuticaquiroga.com/api/landing/contacto/correo', this.contacto)
      console.log(response)
      await this.$msg.ok(response.data.message)

      this.mensaje = "Enviar"
      this.loading = false

      this.contacto = {
        idArea: 1,
        nombre: '',
        apellido: '',
        telefono: '',
        correo: '',
        estado: '',
        codigoPostal: '',
        mensaje: ''
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.como-podemos-ayudarte{
  margin:0em;    
  padding-top: 5em;

  @media only screen and (max-width: 992px) {
    margin:5em 0em;
  }
}

.formulario{
  max-width: 500px; 

  @media only screen and (max-width: 992px) {
    max-width: 100%;
  }
}

.control{
  border: 2px solid #b2b2b2;
  padding: 1em 2em;
  margin:.3em;

  label{    
    color: #242424;
    margin: 0;
  }

  .input{
    width: 100%;
    border: none;
    font-weight: bold;
    
  }
}

.btn-primary{
  background: #1b75bc!important;
  padding: 1em;
}

</style>