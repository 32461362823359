<template>
  <div>    
    <div class="d-none d-lg-block">
        <div class="bar-info">
            <div class="d-flex">
                <div class="mx-3">
                    <ul>
                        <li>
                            <div class="font-weight-bold">Dirección</div>
                            <div>
                                Av. Poniente #2084 Colonia Inf. Las Flores C.P 80159 Culiacán Rosales, Sin.
                            </div>
                        </li>
                    </ul>                
                </div>

                <div class="mx-3">
                    <ul>
                        <li>
                            <div class="font-weight-bold d-flex align-items-center"><img src="@/assets/clock.svg" class="mr-2">Horarios</div>
                            <div>
                                8:00 a 18:30 Hrs
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="mx-3">
                    <ul>
                        <li>
                            <div class="font-weight-bold d-flex align-items-center"><img src="@/assets/phone.svg" class="mr-2">Teléfono</div>
                            <div>
                                800 400 3002
                            </div>
                            <div>
                                667 713 8950
                            </div>
                        </li>
                    </ul>                
                </div>

                <button class="btn btn-outline-light ml-auto mr-3 px-5" href="#contacto" v-smooth-scroll>
                    ¡Quiero ser cliente!
                </button>
            </div>
        </div>
        
        <b-navbar>
            <b-navbar-nav class="mt-4 ml-auto d-flex align-items-center">
                <b-nav-item>
                    <a href="#inicio" v-smooth-scroll>
                        <div class="text-link mx-4">Inicio</div>
                    </a>                    
                </b-nav-item>
                <b-nav-item>
                    <a href="#acerca-de" v-smooth-scroll>
                        <div class="text-link mx-4">Acerca de</div>
                    </a>                    
                </b-nav-item>
                <b-nav-item >
                    <a href="#contacto" v-smooth-scroll>
                        <div class="text-link mx-4">Contacto</div>
                    </a>                    
                </b-nav-item>
                <b-nav-item>
                    <button @click="click" class="btn btn-menu ml-4">
                        <i class="fa-solid fa-cart-shopping mr-2"></i>
                        Ir a comprar
                    </button>
                </b-nav-item>                
            </b-navbar-nav>
        </b-navbar>       
    </div>

    <!-- sm -->
    <b-navbar toggleable="lg" class="d-lg-none menu-sm">
        <b-navbar-brand>
            <img src="@/assets/DFQ_LOGO_HORIZONTAL_1@3x.png" class="logo-small" alt="">
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse">
            <template #default="{ expanded }">
                <div v-if="expanded">
                    <i class="fa-solid fa-xmark menu-text-black"></i>
                </div>
                <div v-else>
                    <i class="fa-solid fa-bars menu-text-black"></i>
                </div>
            </template>
        </b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="menu-nav">
                <b-nav-text>
                    <a href="#inicio" class="nav-link">
                        <div class="text-link">Inicio</div>
                    </a>
                </b-nav-text>
                <b-nav-text >
                    <a href="#acerca-de" class="nav-link">
                        <div class="text-link">Acerca de</div>
                    </a>                    
                </b-nav-text>
                <b-nav-text>
                    <a href="#contacto" class="nav-link">
                        <div class="text-link">Contacto</div>
                    </a>                    
                </b-nav-text>
                <b-nav-item>
                    <button @click="click" class="btn btn-menu">
                        Venta en linea
                    </button>
                </b-nav-item>
                <b-nav-item>
                    <div class="menu-info">
                        <div class="font-weight-bold mb-2">Dirección</div>
                        Av. Poniente #2084 Colonia Inf. Las Flores C.P 80159 Culiacán Rosales, Sin.
                    </div>
                </b-nav-item>
                <b-nav-item>
                    <div class="menu-info">
                        <div class="font-weight-bold mb-2">Teléfono</div>
                        <div>800 400 3002</div>
                        <div>
                            667 713 8950
                        </div>
                    </div>
                </b-nav-item>
            </b-navbar-nav>            
        </b-collapse>        
    </b-navbar>
  </div>
</template>

<script>
export default {
    methods:{
        click(){
            window.location.href='https://www.farmaceuticaquiroga.com/sitio/#/login'
        }
    }

}
</script>

<style lang="scss">

.menu-text-black{
    color:#2b2b2b;
}

.nav-link{
    margin-bottom: 53px;
    display: flex;
    font-family: 'Rubik', sans-serif;

    a{
        text-decoration: none;
    }

    .text-link{                
        color: #212121;
        border-bottom: 2px solid #fff;
        font-size: 19px;
        padding-bottom: .25rem;
        transition: all .1s;

        &:hover{
            // font-size: 24px;
            font-weight: bold;
            border-bottom: 2px solid #212121;;
        }
    }
}

.btn-menu{
    padding: 18px 25px ;
    background: #ff3038;
    color:#fff;
    font-weight: bold;
}


.menu-info{
    color:rgba(0,0,0,1)  
}

.menu-nav{
    margin: 3.5em 1em;
}

.navbar-toggler {
    border: none;
}

.logo-small{
    width: 110px;
}

.menu-sm {
    padding-top: 1.5em;    
}


.bar-info{
    background: #1b75bc;
    color:#fff;
    padding: 1.5em;
}

.show{
    height: 100vh;
}
</style>