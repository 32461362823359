<template>
  <div>
    <div class="nuestros-valores">
      <div class="titulo">Nuestros valores</div>
      <div class="contenido">
        <div class="valores">
          <div class="d-lg-flex justify-content-around">
            <div class="valor">
              <img src="@/assets/honestidad.png" alt="" />
              <div>Honestidad</div>
            </div>

            <div class="valor">
              <img src="@/assets/respeto.png" alt="" />
              <div>Respeto</div>
            </div>

            <div class="valor">
              <img src="@/assets/trabajo-en-equipo.png" alt="" />
              <div>Trabajo en equipo</div>
            </div>
          </div>
          <div class="d-lg-flex justify-content-around mt-lg-3">
            <div class="valor">
              <img src="@/assets/compromiso.png" alt="" />
              <div>Compromiso</div>
            </div>

            <div class="valor">
              <img src="@/assets/responsabilidad.png" alt="" />
              <div>Responsabilidad</div>
            </div>

            <div class="valor">
              <img src="@/assets/espiritu-de-servicio.png" alt="" />
              <div>Espíritu de servicio</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {};
</script>

<style lang="scss" scoped>
.valor {
    display: flex;
    align-items: center;
    border: 1px solid #929292;
    border-radius: 5px;
    padding: 18px 20px;
    margin: 5px;
    font-size: 20px !important;
    width: 300px;
    height: 100px;

    img {
        max-height: 45px;
        margin: 0px 18px;
        max-width: 41px;
    }
}

.nuestros-valores {
    margin-top: 10em;
    margin-bottom: 5em;
    .titulo {
        text-align: center;
        font-size: 30px !important;
        font-weight: bold;
    }
    .contenido {
        display: flex;
        justify-content: center;
        margin-top: 3em;
    }
}
</style>
