<template>
  <div>
    <contacto-component id="contacto" class="margen-seccion" data-aos="opacity-up" ></contacto-component>
  </div>
</template>

<script>
import ContactoComponent from '@/components/ContactoComponent.vue'
export default {
  components:{
    ContactoComponent,    
  }

}
</script>

<style>

</style>