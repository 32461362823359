<template>
  <div>
    <a href="https://www.farmaceuticaquiroga.com/sitio/#/login" class="boton-flotante">
        <i class="fa-solid fa-cart-shopping mr-2"></i>
    </a>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
    .boton-flotante{
        background: #ed1c24;
        padding: 1em;
        font-size: 25px;
        color:#fff;
        border:#ed1c24;
        position: fixed;
        bottom: 1em;
        right: 1em;
        transition: all .2s ease-out;
        opacity: .8;
        z-index: 1000;

        &:hover{
            font-size: 30px;
            opacity: 1;
        }
    }
</style>